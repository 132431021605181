import { Controller } from "@hotwired/stimulus";
import { makeElement } from "../../lib/utils";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static values = {
    modalTemplate: String,
  }

  confirm(event) {
    const modalEl = makeElement(this.modalTemplateValue);
    const modal = new bootstrap.Modal(modalEl);

    modal.show();
  }
};