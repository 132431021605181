import { Controller } from "@hotwired/stimulus";
import { positionElementBesideElement } from "../../lib/utils";

export default class extends Controller {
  static values = {
    id: String,
  };

  connect() {
    this.placeSelfBesideEvent();
  }

  close() {
    this.element.remove();
  }

  placeSelfBesideEvent() {
    const eventElement = document.querySelector(`.event-id-${this.idValue}`);
    const calendarElement = document.querySelector('[data-controller="event-calendar"]');

    positionElementBesideElement(this.element, eventElement, calendarElement);
  }
};