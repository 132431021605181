
const NEW_EVENT_ID = 'new-event-id';

const RSVP_EVENT = 'rsvp_event';
const TICKETED_EVENT = 'ticketed_event';

const PUBLIC_VISIBILITY = 0;
const MEMBERS_VISIBILITY = 1;
const PRIVATE_VISIBILITY = 2;

export {
  NEW_EVENT_ID,
  RSVP_EVENT,
  TICKETED_EVENT,
  PUBLIC_VISIBILITY,
  MEMBERS_VISIBILITY,
  PRIVATE_VISIBILITY,
};