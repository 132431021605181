import { Controller } from "@hotwired/stimulus";

import { TICKETED_EVENT, RSVP_EVENT } from "../lib/events/constants";

export default class extends Controller {
  static targets = [
    'rsvpButton',
    'ticketedButton',
    'eventTypeInput',
    'startTimeInput',
    'endTimeInput',
    'ticketSection'
  ];

  static values = {
    'type': String,
  }

  toggleType(event) {
    const { params } = event;
    const { type } = params;

    this.typeValue = type;
  }

  toggleAllDay(event) {
    const { target } = event;

    if (target.checked) {
      // Convert date-time inputs to dates, truncating time information
      const startDateTime = this.startTimeInputTarget.value;
      const endDateTime = this.endTimeInputTarget.value;

      this.startTimeInputTarget.type = 'date';
      this.startTimeInputTarget.value = startDateTime.split('T')[0];
      this.endTimeInputTarget.type = 'date';
      this.endTimeInputTarget.value = endDateTime.split('T')[0];
    } else {
      // Convert date inputs to datetime-local inputs, adding T00:00 and T23:59 respectively
      const startDate = this.startTimeInputTarget.value;
      const endDate = this.endTimeInputTarget.value;

      this.startTimeInputTarget.type = 'datetime-local';
      this.startTimeInputTarget.value = `${startDate}T00:00`;
      this.endTimeInputTarget.type = 'datetime-local';
      this.endTimeInputTarget.value = `${endDate}T23:59`;
    }
  }

  typeValueChanged() {
    this.toggleEventTypeButtons();
    this.toggleTicketSectionVisibility();

    this.eventTypeInputTarget.value = this.typeValue;
  }

  allDayValueChanged() {
    this.allDayCheckboxTarget.value = this.allDayValue;
  }

  toggleEventTypeButtons() {
    // Toggle the display of the event type button group based on the current event type
    if (this.typeValue === RSVP_EVENT) {
      this.ticketedButtonTarget.classList.remove('e-btn-dark');
      this.ticketedButtonTarget.classList.add('e-btn-outline-dark');

      this.rsvpButtonTarget.classList.remove('e-btn-outline-dark');
      this.rsvpButtonTarget.classList.add('e-btn-dark');
    } else {
      this.rsvpButtonTarget.classList.remove('e-btn-dark');
      this.rsvpButtonTarget.classList.add('e-btn-outline-dark');

      this.ticketedButtonTarget.classList.remove('e-btn-outline-dark');
      this.ticketedButtonTarget.classList.add('e-btn-dark');
    }
  }

  toggleTicketSectionVisibility() {
    // Toggle the visibility of the ticketing accordion section based on the current event type
    if (this.typeValue === TICKETED_EVENT) {
      this.ticketSectionTarget.classList.remove('d-none');
      this.ticketSectionTarget.querySelectorAll('input').forEach(input => {
        input.removeAttribute('disabled');
      });

      // Expand ticket section
      this.ticketSectionTarget.querySelector('[data-role="toggleSectionButton"]').classList.remove('collapsed');
      this.ticketSectionTarget.querySelector('#tickets').classList.add('show');
    } else {
      this.ticketSectionTarget.classList.add('d-none');
      this.ticketSectionTarget.querySelectorAll('input').forEach(input => {
        input.setAttribute('disabled', 'disabled');
      });
    }
  }

  close() {
    this.element.remove();
  }
}