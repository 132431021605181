import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'priceCentsInput',
    'priceFormattedInput',
  ]

  static values = {
    'currency': String,
    'symbol': String
  }

  connect() {
    this.priceFormattedInputTarget.addEventListener('blur', this.formatPrice.bind(this));
    this.priceFormattedInputTarget.addEventListener('input', this.writePriceCents.bind(this))
  }

  formatPrice(event) {
    const input = event.target;
    const value = this.parsePrice(input.value);

    if (isNaN(value)) {
      input.value = '';
    } else {
      input.value = `${this.symbolValue}${value.toFixed(2)} ${this.currencyValue}`;
    }
  }

  writePriceCents(event) {
    const input = event.target;
    const price = this.parsePrice(input.value);

    console.log(this.priceCentsInputTarget);

    this.priceCentsInputTarget.value = Math.round(price * 100)
  }

  parsePrice(value) {
    const cleanedValue = value.replace(/[^\d.]/g, '');
    return parseFloat(cleanedValue);
  }
};