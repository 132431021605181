import { NEW_EVENT_ID } from "./constants"

const isNewEvent = (event) => {
  return event.id === NEW_EVENT_ID;
}

const buildNewEvent = (dateStr, tz) => {
  return {
    title: 'New Event',
    start: dateStr,
    allDay: true,
    id: NEW_EVENT_ID,
    tz: tz,
  };
}

export { isNewEvent, buildNewEvent };