import { makeElement } from '../../utils';

const buildNewEventModal = (event, template, options) => {
  console.log(event);

  const newEventModal = makeElement(template);

  newEventModal.querySelector('input[data-role="start-time"]').value = event.start;
  newEventModal.querySelector('input[data-role="end-time"]').value = event.start;
  newEventModal.querySelector('input[data-role="timezone"]').value = event.tz;

  if (options.returnToDate) {
    const returnToInput = newEventModal.querySelector('input[data-role="return-to"]');
    const returnUrl = new URL(returnToInput.value, window.location.origin);

    returnUrl.searchParams.set('after', options.returnToDate);

    returnToInput.value = returnUrl.pathname + returnUrl.search;
  }

  return newEventModal;
};

export { buildNewEventModal };