import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];
  static values = {
    'nextPageUrl': String
  };

  connect() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMore();
        }
      });
    });

    this.observer.observe(this.contentTarget);
  }

  disconnect() {
    this.observer.disconnect();
  }

  loadMore() {
    const nextPageUrl = this.contentTarget.dataset.nextPageUrl;
    if (nextPageUrl) {
      fetch(nextPageUrl, {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html));
    }
  }
}