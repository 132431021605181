
import { Controller } from '@hotwired/stimulus';
import { makeElement } from '../../lib/utils';

export default class extends Controller {
  static targets = [
    'ticketContainer'
  ];

  static values = {
    'newTicketTemplate': String,
    'ordinal': Number
  };

  addTicket(event) {
    const ordinalizedTemplate = this.newTicketTemplateValue.replace(/idx/g, this.nextOrdinal());
    const newTicket = makeElement(ordinalizedTemplate);

    this.ticketContainerTarget.appendChild(newTicket);
  }

  removeTicket(event) {
    const { params } = event;
    const { persisted, idx } = params;

    const container = event.target.closest('div[data-role="ticketContainer"]');

    if (!persisted) {
      container.remove();
    } else {
      const destroyInput = `<input type="hidden" name="event[event_tickets_attributes][${idx}][_destroy]" value="1" />`;

      container.classList.add('d-none');
      container.insertAdjacentHTML('beforeend', destroyInput);
    }
  }

  nextOrdinal() {
    return ++this.ordinalValue;
  }
};