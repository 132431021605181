import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['active', 'inactive'];
  static targets = ['btn'];

  toggle(event) {
    const { target } = event;

    this.btnTargets.forEach((button) => {
      button.classList.remove(...this.activeClasses);
      button.classList.add(...this.inactiveClasses);
    });

    target.classList.remove(...this.inactiveClasses);
    target.classList.add(...this.activeClasses);
  };
};