import { Controller } from "@hotwired/stimulus";
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = [
    'updateStrategyModal',
    'startTimeInput',
    'endTimeInput'
  ];

  static values = {
    'recurring': Boolean,
  };

  connect() {
    this.initialStartTime = this.startTimeInputTarget.value;
    this.initialEndTime = this.endTimeInputTarget.value;
  }

  confirmUpdateStrategy(event) {
    // If the event is not a recurring event, no update strategy selection is required
    // and we allow the user to update the event without a selection.
    if (!this.recurringValue) {
      return;
    }

    // Otherwise, prevent the update and show the modal to allow the user to select
    // an update strategy of "this event", "this and following events", or "all events"
    event.preventDefault();

    // If the user changes the time of this event, prevent them from selecting the "all"
    // strategy as date changes are incompatible with that strategy. In the case of a 
    if (this.timeChanged()) {
      this.updateAllStrategyOption.classList.add('d-none');
    } else {
      this.updateAllStrategyOption.classList.remove('d-none');
    }

    const modal = new Modal(this.updateStrategyModalTarget);

    modal.show();
  }

  get updateAllStrategyOption() {
    const updateAllStrategyOption = this.updateStrategyModalTarget.querySelector('input[name="strategy"][value="all"]');

    return updateAllStrategyOption.closest('.form-check');
  }

  timeChanged() {
    return this.initialStartTime !== this.startTimeInputTarget.value ||
      this.initialEndTime !== this.endTimeInputTarget.value;
  }
};