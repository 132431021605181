import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dragBar'];

  connect() {
    this.dragBarTarget.addEventListener('mousedown', this.startDrag.bind(this));
    this.dragBarTarget.addEventListener('mouseup', this.endDrag.bind(this));
  }

  disconnect() {
    this.dragBarTarget.removeEventListener('mousedown', this.startDrag.bind(this));
    this.dragBarTarget.removeEventListener('mouseup', this.startDrag.bind(this));
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  startDrag(event) {
    event.preventDefault();

    // Record the initial mouse position and element position
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.originalX = this.element.offsetLeft;
    this.originalY = this.element.offsetTop;

    // Bind the move event to the document to allow for free dragging
    this.onMouseMove = this.drag.bind(this);
    document.addEventListener('mousemove', this.onMouseMove);

    // Add mouseup listener to the document to ensure we can end the drag from anywhere
    this.onMouseUp = this.endDrag.bind(this);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  drag(event) {
    // Calculate the new position
    const dx = event.clientX - this.startX;
    const dy = event.clientY - this.startY;

    // Update the element's position
    this.element.style.left = `${this.originalX + dx}px`;
    this.element.style.top = `${this.originalY + dy}px`;
  }

  endDrag() {
    // Remove the move event listener when the mouse button is released
    document.removeEventListener('mousemove', this.onMouseMove);
  }
};