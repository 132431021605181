
const makeElement = (template) => {
  const decoder = document.createElement('textarea');
  decoder.innerHTML = template;

  const buildContainer = document.createElement('div');
  buildContainer.innerHTML = decoder.value;

  return buildContainer.firstChild;
}

const maybePlaceAndGetRect = (element, container) => {
  if (!document.contains(element)) {
    container.appendChild(element);
  }

  return element.getBoundingClientRect();
};

const positionElementBesideElement = (sourceElement, targetElement, container) => {
  // Position an element beside another element, taking into account screen space

  const targetRect = targetElement.getBoundingClientRect();
  const sourceRect = maybePlaceAndGetRect(sourceElement, container);

  // Default to right of the element
  let top = targetRect.top;
  let left = targetRect.right;

  if (targetRect.right + sourceRect.width > window.innerWidth) {
    left = targetRect.left - sourceRect.width;
  }

  // Show modal below element if there's enough space
  if ((targetRect.top + sourceRect.height) < window.innerHeight) {
    top = targetRect.top;
  }
  // Show modal above element if there's enough space
  else if ((targetRect.bottom - sourceRect.height) > 0) {
    top = targetRect.bottom - sourceRect.height;
  }
  // Calculate where exactly we should place the modal
  else {
    top = (window.innerHeight / 2) - (sourceRect.height / 2);
  }

  sourceElement.style.position = 'fixed';
  sourceElement.style.left = `${left}px`;
  sourceElement.style.top = `${top}px`;

  if (!document.body.contains(sourceElement)) {
    container.appendChild(sourceElement);
  }
}

export { makeElement, positionElementBesideElement };